import { FC } from 'react';
import IconProps from './IconProps';

const MailOpenIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0225 4.19104L8.06245 1.08759C7.41351 0.681548 6.58972 0.681547 5.94077 1.08759L0.980744 4.19104C0.594022 4.4244 0.333984 4.84446 0.333984 5.3312V11.9988C0.333984 12.7323 0.934071 13.3324 1.66751 13.3324H12.3357C13.0691 13.3324 13.6692 12.7323 13.6692 11.9988V5.3312C13.6692 4.84446 13.4092 4.4244 13.0225 4.19104ZM12.3357 11.9988H1.66751V6.66473L5.94161 9.33604C6.59015 9.74138 7.41307 9.74138 8.06161 9.33604L12.3357 6.66473V11.9988ZM8.06161 8.00251C7.41307 8.40785 6.59015 8.40785 5.94161 8.00251L1.66751 5.3312L5.94161 2.65989C6.59015 2.25455 7.41307 2.25455 8.06161 2.65989L12.3357 5.3312L8.06161 8.00251Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MailOpenIcon;
